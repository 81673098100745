exports.default = {
  "$schema": "http://json-schema.org/draft-07/schema#",
  "$ref": "#/definitions/AnyConsolePluginManifest",
  "description": "Schema of Console plugin's `plugin-manifest.json` file.",
  "definitions": {
    "AnyConsolePluginManifest": {
      "anyOf": [
        {
          "$ref": "#/definitions/StandardConsolePluginManifest"
        },
        {
          "$ref": "#/definitions/LegacyConsolePluginManifest"
        }
      ],
      "description": "This type supports both standard and legacy Console dynamic plugin manifest formats.\n\nConsole application automatically adapts the manifest to standard format when loading the given plugin."
    },
    "StandardConsolePluginManifest": {
      "type": "object",
      "additionalProperties": false,
      "properties": {
        "baseURL": {
          "type": "string"
        },
        "extensions": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/Extension"
          }
        },
        "loadScripts": {
          "type": "array",
          "items": {
            "type": "string"
          }
        },
        "registrationMethod": {
          "$ref": "#/definitions/PluginRegistrationMethod"
        },
        "buildHash": {
          "type": "string"
        },
        "name": {
          "type": "string"
        },
        "version": {
          "type": "string"
        },
        "dependencies": {
          "type": "object",
          "additionalProperties": {
            "type": "string"
          }
        },
        "customProperties": {
          "type": "object",
          "properties": {
            "console": {
              "$ref": "#/definitions/ConsoleSupportedCustomProperties"
            }
          },
          "additionalProperties": {}
        }
      },
      "required": [
        "baseURL",
        "extensions",
        "loadScripts",
        "name",
        "registrationMethod",
        "version"
      ],
      "description": "Standard Console dynamic plugin manifest format."
    },
    "Extension": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string"
        },
        "properties": {
          "$ref": "#/definitions/AnyObject"
        },
        "flags": {
          "$ref": "#/definitions/ExtensionFlags"
        }
      },
      "required": [
        "type",
        "properties"
      ],
      "additionalProperties": {}
    },
    "AnyObject": {
      "type": "object",
      "additionalProperties": {},
      "description": "The type `{}` doesn't mean \"any empty object\", it means \"any non-nullish value\".\n\nUse the `AnyObject` type for objects whose structure is unknown."
    },
    "ExtensionFlags": {
      "type": "object",
      "properties": {
        "required": {
          "type": "array",
          "items": {
            "type": "string"
          }
        },
        "disallowed": {
          "type": "array",
          "items": {
            "type": "string"
          }
        }
      },
      "additionalProperties": false
    },
    "PluginRegistrationMethod": {
      "type": "string",
      "enum": [
        "callback",
        "custom"
      ]
    },
    "ConsoleSupportedCustomProperties": {
      "type": "object",
      "properties": {
        "displayName": {
          "type": "string",
          "description": "User-friendly plugin name."
        },
        "description": {
          "type": "string",
          "description": "User-friendly plugin description."
        },
        "disableStaticPlugins": {
          "type": "array",
          "items": {
            "type": "string"
          },
          "description": "Disable the given static plugins when this plugin gets loaded."
        }
      },
      "additionalProperties": false,
      "description": "Additional plugin metadata supported by the Console application."
    },
    "LegacyConsolePluginManifest": {
      "type": "object",
      "additionalProperties": false,
      "properties": {
        "displayName": {
          "type": "string",
          "description": "User-friendly plugin name."
        },
        "description": {
          "type": "string",
          "description": "User-friendly plugin description."
        },
        "disableStaticPlugins": {
          "type": "array",
          "items": {
            "type": "string"
          },
          "description": "Disable the given static plugins when this plugin gets loaded."
        },
        "name": {
          "type": "string"
        },
        "version": {
          "type": "string"
        },
        "dependencies": {
          "type": "object",
          "additionalProperties": {
            "type": "string"
          }
        },
        "extensions": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/Extension"
          }
        }
      },
      "required": [
        "extensions",
        "name",
        "version"
      ],
      "description": "Legacy Console dynamic plugin manifest format."
    }
  }
};